body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'FreightSans Pro';
}

/* @font-face {
  font-family: 'FreightSans Pro';
  src: url('../src/fonts/FreightSans Pro Medium.otf');
  font-style: 'Medium';
  font-weight: 500;
  font-display: fallback;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

.container {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.container--seasonal .slick-slide {
  float: left;
  padding: 0 10px;
}

.container--seasonal .slick-slider .slick-track,
.container--seasonal .slick-slider .slick-list {
  direction: ltr;
}

.container--seasonal .ant-carousel .slick-slider .slick-list {
  margin-right: -10px;
  margin-left: -10px;
}

.container--seasonal .ant-carousel .slick-dots li button {
  background: #8BADA3;
}

.container--seasonal .ant-carousel .slick-dots li.slick-active button {
  background: #8BADA3;
}

.container--seasonal .ant-carousel .slick-dots-bottom {
  bottom: -16px;
}

@media screen and (max-width: 1399px) {
  .container--seasonal .ant-carousel .slick-slider .slick-list {
    margin-right: 0;
    margin-left: 0;
  }
}

@media screen and (max-width: 580px) {
  .container--seasonal .slick-slide {
    padding: 0 0 0 16px;
  }

  .container--seasonal .ant-carousel .slick-slider .slick-list {
    margin-right: -20px;
    margin-left: 0;
    padding: 0 0 0 20px !important;
  }
  
}

.section__title {
  text-align: center;
  font-weight: 700;
  color: #8BADA3;
  font-size: 32px;
  margin-top: 36px;
  margin-bottom: 42px;
}

.grid {
  margin: 0 -10px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-wrap: wrap;
}

.grid > li {
  display: block;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.grid--2 > li {
  width: 50%;
}

.grid--4 > li {
  width: calc(25%);
}

.card {
  position: relative;
  color: #fff;
  cursor: pointer;
}

.card img {
  height: 345px;
  width: 100%;
  object-fit: cover;
}

.card::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 193px;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #000000 100%
  );
}

.card__content {
  position: absolute;
  bottom: 40px;
  left: 0;
  text-align: center;
  width: 100%;
  transition: 0.5s transform ease;
}

.card__title {
  font-size: 22px;
}

.card__details {
  margin: 0;
  direction: rtl;
}

.card__cta {
  color: #fff;
  text-decoration: underline;
  opacity: 0;
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  text-align: center;
  transition: 0.5s opacity ease;
  transition-delay: 0s;
}

.card:hover .card__content {
  transform: translateY(-30px);
}

.card:hover .card__cta {
  opacity: 1;
  transition-delay: 0.2s;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  text-align: center !important;
}

/* TODO: refactor class names */

.forced_ltr {
  direction: ltr !important;
}

.forced_english {
  font-family: FreightSans Pro;
}

.form-wrapper {
  margin: 60px 0;
}
.ant-radio-inner::after{
  margin: 0!important;
}

/* Override ant-design CSS */

.ant-notification {
  direction: ltr !important;
}

.ant-modal-wrap {
  display: flex;
  justify-content: center;
  overflow-x:  hidden !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ant-modal {
  width: auto !important;
}

.ant-select-dropdown-menu {
  padding-right: 0;
}

.ant-select-dropdown-menu-item-group-title {
  font-size: 16px;
  font-weight: bolder;
  color:rgb(56, 54, 54) !important;
}
.ant-btn {
  padding: 0 15px;
}

.ant-select-dropdown-menu-item {
  font-size: 13px;
}

.ant-calendar {
  width: 300px;
}

.ant-carousel {
  height: 100%;
}

.ant-carousel .slick-slider {
  height: 100%;
}

.slick-list {
  height: 100%;
}

.u-flexBetween .ant-form-item-children {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-dropdown-menu {
  text-align: start !important;
}
.ant-form .ant-form-item .ant-form-item-label, .ant-form .ant-form-item .ant-form-item-control {
  text-align: start;
}

.ant-dropdown-menu-item {
  color: #8BADA3;
  padding: 8px 12px 4px 12px;
  position: relative;
  flex-direction: column;
}

.ant-dropdown-menu-item:hover {
  background-color: #f6f7f9;
}

.notifications-menu {
  min-width: 300px;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-inline-start: 18px;
}

.ant-modal-footer {
  text-align: center;
}
.css-1uvcgkx {
  display: block;
  width: 100%;
}
/* @media screen and (max-width: 1025px) {
.css-12bcymh {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: space-evenly !important;
}
} */
